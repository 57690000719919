<template>
<div class=" p-3">
    <div class="d-flex justify-content-between">
        <div class="display-2 font-weight-light mt-2"> Gestion des utilisateurs </div>
         <el-button type="primary" icon="el-icon-plus"  @click="handleAddUser()" style="border-radius:0px;">Ajouter</el-button>
    </div>
    <el-divider class="mt-2"></el-divider>

    <el-skeleton :rows="20" animated v-if="loading" />
    <div v-else>
        <el-table :data="all_data.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))" style="width: 100%">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-tag class="mr-2" disable-transitions v-for="item in scope.row.roles" :key="item.id">{{item.intitule}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="Nom" prop="name" sortable> </el-table-column>
            <el-table-column label="Prenom" prop="surname" sortable> </el-table-column>
            <el-table-column label="Email" prop="email" sortable> </el-table-column>
            <el-table-column label="Poste" prop="function" sortable> </el-table-column>
            <el-table-column label="Agence">
                <template slot-scope="scope">
                    <el-tag disable-transitions>{{scope.row.agences.nom}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="right" >
            <template slot="header" slot-scope="scope">
                <el-input  v-model="search"  size="mini" placeholder="Type to search"/>
            </template>
            <template slot-scope="scope">
                <el-button icon="el-icon-view" @click="handleWatch(scope.$index, scope.row)"></el-button>
                <el-button type="primary" icon="el-icon-edit"  @click="handleEdit(scope.$index, scope.row)"></el-button>
                <el-button type="danger" icon="el-icon-delete" @click="handleDelete(scope.$index, scope.row)"></el-button>
            </template>
            </el-table-column>
        </el-table>
 
        <v-dialog v-model="dialog" max-width="700">
            <AddUserComponent  v-if="type ==1" v-bind:handleClose="handleClose" v-bind:loadData="loadData"/>
            <EditUserComponent  v-if="type ==2" v-bind:handleClose="handleClose" v-bind:form="form" />
            <WatchUserComponent v-if="type ==3"  v-bind:handleClose="handleClose" v-bind:loadData="loadData" v-bind:form="form" />
        </v-dialog>
    </div>
</div>
</template>


<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';

    import AddUserComponent from '@/components/dashboard/Settings/User/AddUserComponent.vue';
    import EditUserComponent from '@/components/dashboard/Settings/User/EditUserComponent.vue';
    import WatchUserComponent from '@/components/dashboard/Settings/User/WatchUserComponent.vue';

    export default {
        
        components: {
            AddUserComponent,
            EditUserComponent,
            WatchUserComponent
        },

        data() {
            return {
                all_data: [],
                search: '',
                loading: true,
                dialog: false,
                type: 0,
                form: '',
            }
        },

        created(){
            this.loadData();
        },
        
        methods: {

            async loadData()
            {
                NProgress.start();

                try {
                
                    const response = await axios.get('xperf/AcceuilColab');

                    this.all_data = response.data.data_T_colab;
                    this.loading = false;


                } 
                catch (error) {

                    this.loading = false;
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();

            },

            handleEdit(index, row) {
                this.form = row;
                this.dialog = true;
                this.type = 2;
            },

            handleWatch(index, row) {
                this.form = row;
                this.dialog = true;
                this.type = 3;
            },

            handleDelete(index, row) {

                this.$confirm("Souhaiter effacer l'utilisateur. Continuer?", 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Annuler',
                type: 'warning'
                }).then(() => {
                    this.delete(row);
                }).catch(() => {
                    this.$notify({
                        type: 'info',   
                        message: 'Suppression annulée'
                    });          
                });
            },

            handleAddUser(){
                this.dialog = true;
                this.type = 1;
            },

            handleClose(){
                this.dialog = false;
            },

            async delete(user) {

                NProgress.start();
                try {

                    const response = await axios.post('v1/xperf/setting/user/delete', user);
                    this.loadData();
                    this.$notify({
                        type: 'success',
                        title: 'Suppression',
                        message: 'Utilisateur supprimé avec succès'
                    });
                } 
                catch (error) {

                    this.loading = false;
                    this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                NProgress.done();
            },
            
        },
    }
</script>