<template>

        <v-card class="text-left">
            <v-card-title>
                <div class="subtitle-1 font-weight-light text-left ml-3 mt-2"> Consulter un utilisateur</div>
                <v-spacer />
                <v-icon aria-label="Close" @click="handleClose()" >
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <div class="subtitle-1 font-weight-light text-left mt-2">Information de l'utilisateur</div>
                    <el-divider></el-divider>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.name" label="Nom" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.surname" label="Prenom" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <!-- <div class="subtitle-10 font-weight-light"> Date de naissance</div> -->
                        <el-date-picker v-model="form.date_naiss" type="date" placeholder="Modifier votre date de naissance" class="mt-3" disabled></el-date-picker>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.phone" label="Telephone personel" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.quartier" label="Quartier" disabled/>
                    </v-col>
                    <v-col cols="12" md="4">
                        <el-select v-model="sexe" placeholder="Sexe" class="mt-2" disabled>
                            <el-option v-for="item in sexes" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </v-col>


                    <div class="subtitle-1 font-weight-light text-left mt-2">Identifiant de connexion</div>
                    <el-divider></el-divider>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.email" label="Email" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.username" label="Identifiant" disabled/>
                    </v-col>

                    <div class="subtitle-1 font-weight-light text-left mt-2">Information de l'entreprise</div>
                    <el-divider></el-divider>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.phone_p" label="Telephone professionnel" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.service" label="Service" disabled/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.function" label="Poste" disabled/>
                    </v-col>
                    
                </v-row>
            </v-card-text>
        </v-card>
    
</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';
    import moment from 'moment';

    export default {

        components: {
        },

        props: ['handleClose','form'],

        data() {

            return {
                sites : [],
                site : '',
                sexe :'',
                sexes: [{
                    value: 'M',
                    label: 'Masculin'
                    }, {
                    value: 'F',
                    label: 'Feminin',
                    }
                ],
            }
        },
        
        mounted(){
            this.sexe = this.form.sexe;
        },
    }
</script>

<style>
    
    element.style {
        overflow:hiden;
    }
</style>