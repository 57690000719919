<template>
    <el-tabs type="border-card" class="m-2" style="height:90vh; overflow: scroll;">
        <el-tab-pane label="Préferences" v-if="permissions.includes('preference')">
        </el-tab-pane>
        <el-tab-pane label="Notifications" v-if="permissions.includes('notifications')">
        </el-tab-pane>
        <el-tab-pane label="Entreprise" v-if="permissions.includes('entreprise')">
            <EntrePrisePage />
        </el-tab-pane>
        
        <el-tab-pane label="Agences" v-if="permissions.includes('agence')">
            <CrudAgencePage />
        </el-tab-pane>

        <el-tab-pane label="Banques" v-if="permissions.includes('banque')">
            <CrudBanquePage />
        </el-tab-pane>
        
        <el-tab-pane label="Utilisateurs" v-if="permissions.includes('utilisateur')">
            <CrudUserPage />
        </el-tab-pane>

        <el-tab-pane label="Roles & Permissions" v-if="permissions.includes('role')">
            <CrudRolePage />
        </el-tab-pane>

        <el-tab-pane label="Permissions" v-if="permissions.includes('permission')">
            <CrudPermissionPage />
        </el-tab-pane>
        
    </el-tabs>
</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';

        import CrudUserPage from './Setting/Crud/CrudUserPage.vue';
        import CrudAgencePage from './Setting/Crud/CrudAgencePage.vue';
        import CrudBanquePage from './Setting/Crud/CrudBanquePage.vue';
        import CrudPermissionPage from './Setting/Crud/CrudPermissionPage.vue';
        import CrudRolePage from './Setting/Crud/CrudRolePage.vue';
        import EntrePrisePage from './Setting/EntrePrisePage.vue';

    export default {

        components:{
            CrudUserPage,
            CrudAgencePage,
            CrudBanquePage,
            CrudPermissionPage,
            CrudRolePage,
            EntrePrisePage
        },
        data(){

            return {

                permissions: [],
            }
        },

        created(){
            this.permission();
        },

        methods:{
            
            permission() {
                    this.permissions =  JSON.parse(localStorage.getItem('menu'));
                    this.permissions = this.permissions.setting[0];
                },

        },

        
    }
</script>