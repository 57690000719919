<template>

        <v-card class="text-left">
            <v-card-title>
                <div class="subtitle-1 font-weight-light text-left ml-3 mt-2"> Ajout d'un utilisateur</div>
                <v-spacer />
                <v-icon aria-label="Close" @click="handleClose()" >
                    mdi-close
                </v-icon>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <div class="subtitle-1 font-weight-light text-left mt-2">Information de l'utilisateur</div>
                    <el-divider></el-divider>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.name" label="Nom"/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.surname" label="Prenom"/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <!-- <div class="subtitle-10 font-weight-light"> Date de naissance</div> -->
                        <el-date-picker v-model="form.date_naiss" type="date" placeholder="Modifier votre date de naissance" class="mt-3"></el-date-picker>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.phone" label="Telephone personel"/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.quartier" label="Quartier"/>
                    </v-col>
                    <v-col cols="12" md="4">
                        <el-select v-model="sexe" placeholder="Sexe" class="mt-2">
                            <el-option v-for="item in sexes" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </v-col>


                    <div class="subtitle-1 font-weight-light text-left mt-2">Identifiant de connexion</div>
                    <el-divider></el-divider>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="form.email" label="Email"/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="password.confirm" label="Mot de passe"/>
                    </v-col>
                    <v-col cols="12" md="4" >
                        <v-text-field class="purple-input" v-model="password.verif" label="Verification de mot de passe"/>
                    </v-col>



                    <div class="subtitle-1 font-weight-light text-left mt-2">Information de l'entreprise</div>
                    <el-divider></el-divider>
                    <v-col cols="12" md="6">
                        <div class="subtitle-10 font-weight-light">Choisir l'agence</div>
                        <el-select v-model="form.agence_id" placeholder="Select">
                            <el-option v-for="item in sites" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </v-col>
                    <v-col cols="12" md="6" >
                        <v-text-field class="purple-input" v-model="form.phone_p" label="Telephone professionnel"/>
                    </v-col>
                    <v-col cols="12" md="6" >
                        <v-text-field class="purple-input" v-model="form.service" label="Service"/>
                    </v-col>
                    <v-col cols="12" md="6" >
                        <v-text-field class="purple-input" v-model="form.function" label="Poste"/>
                    </v-col>
                    
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-row>
                    <v-col cols="12" class="text-right" >
                        <el-button type="warning" round  @click="handleClose()">Annuler</el-button>
                        <el-button type="primary" round @click="handleSave()" >Enregistrer</el-button>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    
</template>
<script>

    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';
    import moment from 'moment';

    export default {

        components: {
        },

        props: ['handleClose','loadData','form'],

        data() {
            return {
                search: '',
                loading: true,
                password: {
                    "confirm"  : "",
                    "verif"  : "",
                },

                sites : [],
                site : '',
                sexe :'',
                sexes: [{
                    value: 'M',
                    label: 'Masculin'
                    }, {
                    value: 'F',
                    label: 'Feminin',
                    }
                ],
            }
        },

        created(){
            this.formatSite();
        },
        methods: {

            formatSite(){
                let All_site = JSON.parse(localStorage.getItem('site'));

                All_site.forEach(element => {

                    let el = {
                        label: element.nom,
                        value: element.id,
                    };

                    this.sites.push(el);
                });
            },

            handleEdit(index, row) {
                this.dialog = true;
                this.form = row;
            },

            handleSave() {
                this.checkPassword();
            },

            checkPassword(){

                if (this.password.confirm === this.password.verif) {

                    this.form.password = this.password.confirm;
                    this.update();
                }
                else{
                    this.$notify({
                        type: 'error',
                        title: "Le Mot de passe ne correspond pas"
                    });
                }
                
            },

            async update() {
                
                NProgress.start();

                try {

                    this.form.date_naiss = moment(new Date(this.form.date_naiss)).startOf('day').format('YYYY-MM-DD');

                    this.form.username = this.form.email;
                    this.form.sexe = this.sexe.value;

                    const response = await axios.post('v1/xperf/setting/user/edit', this.form);
                    this.handleClose();
                    this.loadData();
                    this.$notify({
                            type: 'success',
                            title: "Enregistrement",
                            message: "Utilisateur enregistrer avec succès"
                    });
                } 
                catch (error) {
                    this.handleClose();
                    this.$notify({
                        type: 'error',
                        title: "Enregistrement",
                        message: "Echec d'enregistrement"
                    });
                }

                NProgress.done();

            },
        },
    }
</script>

<style>
    
    element.style {
        overflow:hiden;
    }
</style>